import { useContext, useEffect, useId, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import clsx from 'clsx';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { fetchBookingAvailability } from '@quno/patient-journey/src/data/fetch/calendar/fetchBookingAvailability';
import { useTranslation } from '@quno/patient-journey/src/hooks/useTranslation';
import { ModalContext } from '@quno/patient-journey/src/context/ModalContext';
import { useBrand } from '@quno/patient-journey/src/hooks/useBrand';
import { PatientContext } from '@quno/patient-journey/src/context/PatientContext';
import { createAppointment } from '@quno/patient-journey/src/data/fetch/calendar/createAppointment';
import { useRouter } from 'next/router';
import ExternalFunnel from '../shared/ExternalFunnel/ExternalFunnel';
import layoutStyles from '../shared/Layout/Layout.module.scss';
import { createDatePickerElement } from './createDatePickerElement';
import styles from './FunnelCalendarBooking.module.scss';
import { InformationSection } from './InformationSection/InformationSection';
import { LoadingCalendar } from './LoadingCalendar/LoadingCalendar';
import { CalendarErrorMessage } from './CalendarErrorMessage';
import { Calendar } from './Calendar';
import { SingleInformationSection } from './SingleInformationSection/SingleInformationSection';
import type { AppointmentStatus } from './Calendar';
import type {
  DateTimePickerElement,
  Participant,
  Slot,
} from 'cronofy-elements';
import type { BookingAvalabilityOptionsSuccess } from '@quno/patient-journey/types/BookingAvalabilityOptions';
import type { Package } from '@quno/patient-journey/src/contentful/patient-journey/blocks/BlockCard';

export type FunnelCalendarBookingProps = {
  cid?: string;
  eventSetupId?: string;
  inviteId?: string;
  funnelId?: string;
  contentfulPackage?: Package;
};

const errorCodes = ['existing_booking', 'unexpected_error', 'expired_invite'];

export const FunnelCalendarBooking = ({
  cid,
  inviteId,
  eventSetupId = '',
  funnelId = '',
  contentfulPackage,
}: FunnelCalendarBookingProps): JSX.Element => {
  const router = useRouter();
  const id = useId();
  const inspectorProps = useContentfulInspectorMode({
    entryId: cid,
  });
  const target = `date-time-picker-${id}`;

  const [element, setElement] = useState<DateTimePickerElement | null>(null);
  const [status, setStatus] = useState<AppointmentStatus>('loading');
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [options, setOptions] =
    useState<BookingAvalabilityOptionsSuccess | null>(null);
  const { t, locale } = useTranslation(true);
  const { modal, setModal } = useContext(ModalContext);
  const isMultiple = (options?.information?.doctors?.length || 0) > 1;
  const brand = useBrand();
  const { patient } = useContext(PatientContext);
  const [isCreateAppointmentLoading, setIsCreateAppointmentLoading] =
    useState(false);

  const openFunnel = async (slot: Slot): Promise<void> => {
    const customFieldOverrides: { [key: string]: string | unknown } = {
      appointment_slot: slot,
      appointment_eventsetupid: eventSetupId,
    };

    if (contentfulPackage?.sys?.id) {
      customFieldOverrides.contentful_package_id = contentfulPackage.sys.id;
    }

    await window?.openExternalFunnel({
      funnelId,
      locale,
      configFromSameDomain: true,
      customFieldOverrides,
      customLogoOverride: brand?.logo,
      behavior: 'fullscreen',
    });
  };

  useEffect(() => {
    if (element) {
      return;
    }

    const load = async (): Promise<void> => {
      const options = await fetchBookingAvailability(
        eventSetupId,
        undefined,
        inviteId,
        contentfulPackage?.sys.id,
      );

      if ('error' in options) {
        const { key } = options.error;

        const existingKey = errorCodes.includes(key);
        setStatus(existingKey ? key : 'unexpected_error');

        return;
      }

      if (!patient || options.information.dayPrecision === 'date') {
        options.config = {
          ...options.config,
          mode: 'no_confirm',
        };
      }

      if (options.information.doctors.length > 1) {
        options.information.doctors = [
          {
            name: t('calendar-booking.anyDoctor'),
          },
          ...options.information.doctors,
        ];
      }

      setOptions(options);

      setStatus('loaded');

      const datePickerElement = await createDatePickerElement(
        target,
        options,
        locale,
        async (slot) => {
          if (patient) {
            setIsCreateAppointmentLoading(true);

            await createAppointment(
              patient.junoId,
              slot,
              eventSetupId,
              inviteId,
              contentfulPackage?.sys.id,
            );

            setIsCreateAppointmentLoading(false);

            await router.replace(
              `/quotes/${`${patient.firstName}.${patient.lastName?.[0]}`}/${
                patient.junoId
              }`,
            );

            return;
          }

          await openFunnel(slot);

          setModal({ content: null, isOpen: false });
        },
        async (date) => {
          setSelectedDate(date);
        },
        t,
      );

      setElement(datePickerElement);
    };

    void load();
  }, [eventSetupId, inviteId, locale, target, patient]);

  const onSelectedDoctor = (doctorSub: string | undefined): void => {
    if (!options || !element) {
      return;
    }

    const anyDoctor = options?.availability_query.participants.find(
      (participant) => participant.type === 'doctor',
    ) as Participant;

    const selectedDoctorParticipant: Participant = {
      members: anyDoctor.members.filter((member) => member.sub === doctorSub),
      required: 1,
      type: 'doctor',
    };
    const doctorParticipation = doctorSub
      ? selectedDoctorParticipant
      : anyDoctor;

    const newParticipants = options?.availability_query.participants.map(
      (participant) =>
        participant.type === 'doctor' ? doctorParticipation : participant,
    );

    const newOptions: BookingAvalabilityOptionsSuccess = {
      ...options,
      config: {
        selected_date: selectedDate!,
        mode: 'no_confirm',
      },
      availability_query: {
        ...options?.availability_query,
        participants: newParticipants,
      },
    };

    element.update(newOptions);
  };

  const loading = status === 'loading';
  const { eventTypeName } = options?.information || {};

  const calendarTitle = contentfulPackage
    ? contentfulPackage.name
    : t('calendar-booking.title', {
        type: eventTypeName,
      });

  return (
    <div
      className={clsx(layoutStyles.root, styles.wrapper)}
      {...inspectorProps?.({ fieldId: 'eventSetupId' })}
    >
      <div
        className={clsx(
          styles.container,
          !modal?.isOpen && styles.containerWithBorder,
        )}
      >
        {errorCodes.includes(status) && (
          <CalendarErrorMessage
            title={t(`calendar-booking.errors.${status}.title`)}
            description={t(`calendar-booking.errors.${status}.description`)}
          />
        )}

        {(status === 'loaded' || loading) && (
          <>
            <h1>{loading ? <Skeleton /> : calendarTitle}</h1>

            <div className={styles.section}>
              {isMultiple ? (
                <InformationSection
                  loading={loading}
                  information={options?.information}
                  onSelectedDoctor={onSelectedDoctor}
                />
              ) : (
                <SingleInformationSection
                  information={options?.information}
                  selectedDoctor={options?.information?.doctors?.[0].sub}
                />
              )}

              <div className={styles.calendar}>
                {isCreateAppointmentLoading && (
                  <div className={styles.overlay} />
                )}

                {loading && <LoadingCalendar />}

                <Calendar
                  target={target}
                  status={status}
                  hiddenSlots={options?.information.dayPrecision === 'date'}
                />
              </div>
              <ExternalFunnel
                configFromSameDomain
                funnelId={funnelId}
                preload
                behavior="fullscreen"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
